import {api} from "@iblai/ibl-web-react-common";
import React from "react";
import {useParams} from "react-router-dom";

export interface CourseBlock {
    id: string;
    block_id: string;
    lms_web_url: string;
    legacy_web_url: string;
    student_view_url: string;
    type: string;
    display_name: string;
    graded: boolean;
    has_score: boolean;
    resume_block: boolean;
    completion?: number;
    complete?: boolean;
    scored?: boolean;
    num_graded_problems?: number;
    children?: CourseBlock[];
}

interface CourseOutlineContent {
    id : string;
    unitName: string;
    blockData: string | undefined;
}

interface CourseOutlineSubsection {
    subsectionName: string;
    contents: CourseOutlineContent[];
    complete: any;
}

interface BlockData {
    id: string;
    blockData: {
        name: string | undefined;
        completion: number | boolean;
    }[];
}

interface CourseOutlineUnit {
    unitName: string;
    id: string;
    blockData: BlockData[];
}

export interface CourseOutline {
    semesterName: string;
    subsections: CourseOutlineSubsection[];
    units: CourseOutlineUnit[];
}

function courseOutlineProblemType(unitData: CourseBlock): string | undefined {
    const typeMap: { [key: string]: string } = {
        "problem": unitData.display_name,
        "video": unitData.display_name,
        "html": unitData.display_name,
    };

    if (unitData.display_name === "Raw HTML" || unitData.display_name === "Duplicate of 'Raw HTML'") {
        return undefined;
    }

    return typeMap[unitData.type] || unitData.type || "Unknown";
}

export function createCourseOutline(data: { children?: CourseBlock[] }): CourseOutline[] {
    return (data.children || []).map(semesterData => ({
        semesterName: semesterData.display_name,
        subsections: (semesterData.children || []).map(subsectionData => ({
            complete: subsectionData.children?.every((contentData) => contentData.complete),
            subsectionName: subsectionData.display_name,
            contents: (subsectionData.children || []).map(contentData => ({
                unitName: contentData.display_name,
                id : contentData.id,
                blockData: courseOutlineProblemType(contentData),
            })),
        })),
        units: (semesterData.children || []).map(subsectionData => ({
            unitName: subsectionData.display_name,
            id: subsectionData.id,
            blockData: (subsectionData.children || []).map(contentData => ({
                id: contentData.id,
                blockData: (contentData.children || []).map(unitData => ({
                    name: courseOutlineProblemType(unitData),
                    completion: unitData.completion || false,
                })),
            })),
        })),
    }));
}

export function extractContents(data: { children?: CourseBlock[] }): CourseOutlineContent[] {
    return (data.children || []).flatMap(semesterData =>
        (semesterData.children || []).flatMap(subsectionData =>
            (subsectionData.children || []).map(contentData => ({
                unitName: contentData.display_name,
                id: contentData.id,
                blockData: courseOutlineProblemType(contentData),
            }))
        )
    );
}



export const getFirstUnitId = (units: CourseOutlineContent[]): string => {
    return units.length > 0 ? units[0].id : '';
};

export const getNextUnitId = (units: CourseOutlineContent[], currentId: string): string => {
    const index = units.findIndex(unit => unit.id === currentId);
    if (index !== -1 && index < units.length - 1) {
        return units[index + 1].id;
    }
    return currentId;
};



export function createOutlineHTML(
    data: CourseOutline,
    currentUnit: string,
    handleOutlineClick: (unitID: string) => void
): JSX.Element[] {
    const subsections: CourseOutlineSubsection[] = data.subsections || [];

    return subsections.flatMap((subsection, subsectionIndex) =>
        subsection.contents.map((section, contentIndex) => {
            if (currentUnit === section.id) {
                document.querySelector('.chapter-one-what-to-expect')!.textContent = section.unitName;
            }

            return (
                <div className="faq-content-2" key={`${subsectionIndex}-${contentIndex}`}>
                    <div
                        className={`module_row third_lvl ${currentUnit === section.id ? "active" : ""}`}
                        id={section.id}
                        onClick={() => handleOutlineClick(section.id)}
                    >
                        <div className="div-block-47 w-clearfix">
                            <div className="question-title-2">
                                <div className="link-44" style={{marginBottom: "20px"}}>
                                    {section.unitName}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        })
    );
}


export function getCourseOutline(courseID: string, callback: (data: any) => any): void {
    const data = {
        course_id: courseID,
    };

    api.ibledxcourses.getCourseOutline(data, (responseData: any) => {
        callback(responseData);
    });


}


export function listUncompletedUnits(courseOutline: CourseOutline[]): BlockData[] {
    const uncompletedBlocks: BlockData[] = [];

    for (const outline of courseOutline) {
        for (const unit of outline.units) {
            for (const block of unit.blockData) {
                const hasIncompleteData = block.blockData.some(data => data.completion !== true && data.completion !== 1);
                if (hasIncompleteData) {
                    uncompletedBlocks.push(block);
                }
            }
        }
    }

    // Remove the last item from the uncompletedBlocks array since its the conclusion section, which will always be uncompleted by the user
    uncompletedBlocks.pop();
    return uncompletedBlocks;
}


export function addIframeUrl(blockID: string, callback: (arg: string) => void, courseID: string): void {


    let data = {
        course_id: courseID,
        xblock_id: blockID,
        mfe_url: process.env.REACT_APP_IBL_MFE_URL,
    };
    try{
        callback(api.ibledxcourses.getMFEURL(data));
    }catch{
        callback("");
    }
}


