import { useState } from "react"
import useHeaderFooter from "../../hooks/useHeaderFooter";
import {api, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import "./Faq.css"
import {NavLink} from "react-router-dom";

interface FAQItemProps {
    question: string
    answer: string | React.ReactNode
}

interface FaqProps {
    children?: React.ReactNode
}

const FAQItem = ({ question, answer }: FAQItemProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
                <div className="faq-marker"></div>
                <h3>{question}</h3>
                <div className={`faq-icon ${isOpen ? "open" : ""}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L12 15L18 9" stroke="#8B7EB5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            {isOpen && (
                <div className="faq-answer">
                    <div>{answer}</div>
                </div>
            )}
        </div>
    )
}

const Faq : React.FC<FaqProps> = ({children}) => {
    const {headerContent, footerContent} = useHeaderFooter(children);

    const faqData = [
        {
            question: "HOW DO I REGISTER A NEW ACCOUNT?",
            answer:
            <p>
                <ul>
                    <li>
                        <p>Go to <NavLink className={"faq-link"} to={"/"}>www.leadingwithcharacter.com</NavLink> and click <b>Login</b> at the upper right-hand corner of the page. <br/>
                        <img src="/img/faq/register-account.png" className="" />
                        </p>
                    </li>
                    <li>
                        <p>
                            Click <b>Don’t have an account? Sign Up</b> and enter your details as requested.
                            <br/>
                            <img src="/img/faq/signup-account.png" className="" />
                            <img src="/img/faq/signup-account-2.png" className="" />
                        </p>
                    </li>
                </ul>
            </p>

        },
        {
            question: "HOW DO I CHANGE MY PASSWORD?",
            answer:
                <p>
                    <ul>
                        <li>
                            <p>Click <b>Login</b> and go to the <b>login page</b>.<br/>
                                <img src="/img/faq/register-account.png" className="" />
                            </p>
                        </li>
                        <li>
                            <p>
                                Enter your email address or username and click <b>continue</b>.
                            </p>
                        </li>
                        <li>
                            <p>
                                Click <b>forgot password?</b> and follow the instructions to reset a password.
                                <img src="/img/faq/forget-password.png" className="" />
                            </p>
                        </li>
                    </ul>
                </p>
        },
        {
            question: "I'M HAVING TROUBLE SIGNING IN. WHAT SHOULD I DO?",
            answer:
                <p>
                    <ul>
                        <li>
                            <p>Check your credentials. Ensure you are using the correct email and password. If using autofill, try entering your details manually
                            </p>
                        </li>
                        <li>
                            <p>
                                Clear your browser history, cache, and cookies.
                            </p>
                        </li>
                        <li>
                            <p>
                                Try a different browser and device.
                            </p>
                        </li>
                        <li>
                            <p>
                                Try resetting your password – use the <b>forgot password</b> option to reset it.
                                <img src="/img/faq/forget-password.png" className="" />
                            </p>
                        </li>

                    </ul>
                </p>
        },
        {
            question: "WHY HAVE MY VIDEOS STOPPED PLAYING?",
            answer:
                <p>
                    <ul>
                        <li>
                            <p>Refresh the page or try a different browser.
                            </p>
                        </li>
                        <li>
                            <p>
                                Check your internet connection.
                            </p>
                        </li>
                        <li>
                            <p>
                                Clear your browser history, cache, and cookies.
                            </p>
                        </li>
                        <li>
                            <p>
                                Disable any browser extensions that might block videos.
                            </p>
                        </li>

                    </ul>
                </p>
        },
        {
            question: "I CAN'T PROGRESS TO THE NEXT MODULE, EVEN THOUGH I'VE COMPLETED EVERYTHING.",
            answer:
                <p>
                    <ul>
                        <li>
                            <p>	Check that all unit tabs within the module display green checkmarks.
                                When you complete a unit, the tab associated with that unit turns green and a
                                check mark appears. In the screen shot below, you will see that the first two
                                units are completed as shown by the green tabs. If a tab remains white
                                (units 3 through 9 in the screenshot below), that means that not all activities in those units are registered as complete. Go back to the corresponding unit and read all text, watch all videos, and click on all hotspots.
                                <img src="/img/faq/introduction-course-content.png" className="" />
                                In order for a unit to be marked complete, you must watch the videos in entirety, read all text, answer all quiz questions, and click and read all hotspots as shown in the screenshot below.
                                <img src="/img/faq/course-content-core-commitments.png" className="" />
                                <img src="/img/faq/course-content-banner.png" className="" />
                            </p>
                        </li>
                    </ul>
                </p>
        },
        {
            question: "WHY DOES THE CONTENT NOT LOAD, AND WHY DO THE MODULES APPEAR BLANK?",
            answer:
                <p>
                    <ul>
                        <li>
                            <p>Refresh the page or try accessing it in a different browser.
                            </p>
                        </li>
                        <li>
                            <p>
                                Check your internet connection.
                            </p>
                        </li>
                        <li>
                            <p>
                                Clear your browser history, cache, and cookies.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ensure your browser and operating system are up to date.
                            </p>
                        </li>
                        <li>
                            <p>
                                Disable any extensions that may block content.
                            </p>
                        </li>

                    </ul>
                </p>
        },
        {
            question: "WHERE CAN I FIND MY COMPLETION CERTIFICATE?",
            answer:
                <p>
                    <ul>
                        <li>
                            <p>	Hover over the profile icon in the upper right-hand corner and click on <b>Dashboard</b>.
                                <img src="/img/faq/completion-certificate.png" className="" />
                            </p>
                        </li>
                        <li>
                            <p>	Click on the icon of the completed module for which you would like a certificate. If you would like to download the full course completion certificate, click on the <b>Leading Forward</b> icon. Download the certificate that appears.
                                <img src="/img/faq/profile-certificates.png" className="" />
                            </p>
                        </li>
                        <li>
                            <p>	If you would like to change the name appearing on your certificate, complete fields for your full name by clicking on the pencil icon next to <b>My Personal Information</b>.
                            </p>
                        </li>
                    </ul>
                </p>
        },
        {
            question: "THE NAME ON MY CERTIFICATE IS INCORRECT. HOW CAN I CHANGE IT?",
            answer:
                <p>
                    <ul>
                        <li>
                            <p>	If you would like to change the name appearing on your certificate, complete fields for your full name by clicking on the pencil icon next to “My Personal Information”.
                                <img src="/img/faq/edit-profile.png" className="" />
                            </p>
                        </li>
                    </ul>
                </p>
        },
        {
            question: "HOW CAN I STAY UP TO DATE ABOUT ADDITIONAL COURSES OR RESOURCES ON LEADING WITH CHARACTER?",
            answer:
                <p>
                    To stay up to date on future Leading with Character webinars, courses, and events, follow the following social media accounts:
                    <br/>
                    LinkedIn: <a className="faq-link" href={"https://www.linkedin.com/company/LeadingWithCharacter/"} target={"_blank"}>https://www.linkedin.com/company/LeadingWithCharacter/</a>
                    <br/>
                    X: <a className="faq-link" href={"https://x.com/LWCCourse"} target={"_blank"}>https://x.com/LWCCourse</a>
                </p>
        },
        {
            question: "STILL EXPERIENCING ISSUES?",
            answer:
                <p>
                    If the problem persists after trying all the steps above, please contact support at <a className="faq-link no-line-break" href={"mailto:support@leadingwithcharacter.com"}>support@leadingwithcharacter.com</a>.
                </p>
        },

    ]

    return (
        <>
            <input type="hidden" id="anPageName" name="page" defaultValue="about"/>
            <div className="container-center-horizontal">
                <div className="about screen contact">
                    <div className="homepage ">
                        {headerContent}
                        <div className="overlap-group6">
                            <div className="hero-image">
                                <div className="overlap-group-1">
                                    <img
                                        className="overlap-group-item"
                                        src="/img/CONTACTUS-LWC.jpg"
                                        alt="robert-bock-cV4qkkorDFY-unsplash 1"
                                    />
                                    <h1 className="about-us recklessneue-regular-normal-white-100-1px">
                <span className="recklessneue-regular-normal-white-100-1px">
                  Frequently Asked Questions
                </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faq-container">
                        <div className="video-container">
                            <iframe
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/KgDDCC6QKeI?si=LO-vK9pOfAj3Z4yt`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                        {/*<p className="faq-intro">
                            Whether you want to know how we make grants or what countries we work in, you've come to the right place.
                        </p>*/}
                        <div className="faq-list">
                            {faqData.map((item, index) => (
                                <FAQItem key={index} question={item.question} answer={item.answer} />
                            ))}
                        </div>
                    </div>
                    {footerContent}
                </div>
            </div>
        </>
    )
}

export default Faq;