import useHeaderFooter from "../../hooks/useHeaderFooter";
import { useLocation, useParams } from "react-router-dom";
import CourseNavigation from "../../components/CourseNavigation/CourseNavigation";
import React, { useEffect, useState } from "react";
import "../../css/CourseWorking.css";
import useCoursePreview from "../../hooks/useCoursePreview";
import { addSubdomain } from "../../hooks/utils/helpers";

interface CourseProgressProps {
  children?: React.ReactNode;
}

const LMS_BASE_URL = process.env.REACT_APP_IBL_LMS_URL;

const CourseProgress: React.FC<CourseProgressProps> = ({ children }) => {
  const { headerContent, footerContent } = useHeaderFooter(children);
  const { courseID } = useParams<{ courseID: string }>();
  const [edxIframeUrl, setEdxIframeUrl] = useState("");
  const [isCourseProgress, setIsCourseProgress] = useState(false);

  const { courseInfo, courseInfoLoaded } = useCoursePreview(courseID);

  useEffect(() => {
    populateCourseProgress();
  }, [courseID]);

  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname.includes("course-notes")) {
      populateCourseNotes();
      setIsCourseProgress(false);
    } else if (pathname.includes("course-progress")) {
      populateCourseProgress();
      setIsCourseProgress(true);
    }
  }, [pathname]);

  function populateCourseProgress() {
    setEdxIframeUrl(
      `${addSubdomain("apps")}/learning/course/${courseID}/progress/`
    );
  }

  function populateCourseNotes() {
    setEdxIframeUrl(
      `${process.env.REACT_APP_IBL_LMS_URL}/courses/${courseID}/edxnotes/`
    );
  }

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        defaultValue="course-progress"
      />
      <div className="container-center-horizontal progress-div">
        <div className="course-progress screen">
          <div className="overlap-group8">
            <div className="overlap-group6">
              <div className="hero-image">
                <div className="overlap-group">
                  {/*TODO : change pictures based on courses here*/}
                  <img
                    className="inbal-marilli-lg-ui"
                    src={`${LMS_BASE_URL}${courseInfo?.banner_image_asset_path}`}
                  />
                  <div className="rectangle-20" />
                </div>
              </div>
              <div className="headline">
                <h1 className="course-01-why-lead instrumentserif-normal-white-100-1px-2">
                  <span className="instrumentserif-normal-white-100-1px">
                    Module
                    <br />
                  </span>
                  <span className="recklessneue-regular-normal-white-100-1px c_title">
                    {courseInfoLoaded && courseInfo.display_name}
                  </span>
                </h1>
              </div>
            </div>
            {headerContent}
            <div id="page-nav">
              <div className="page-nav">
                <div className="nav-cont">
                  <div className="progress-nav">
                    <img
                      className="arrow-1 arrow nav_btn previous_btn"
                      src="/img/arrow-1.svg"
                      alt="Arrow 1"
                    />
                    <p className="chapter-one-what-to-expect recklessneue-book-normal-congress-blue-22-8px c-active">
                      Gateway
                    </p>
                    <img
                      className="arrow nav_btn next_btn"
                      src="/img/arrow-2-1.svg"
                      alt="Arrow 2"
                    />
                  </div>
                  <CourseNavigation courseID={courseID} />
                </div>
              </div>
            </div>
          </div>

          {isCourseProgress && (
            <>
              <div className="course-progress-intro-text">
                <div className="course-progress-1">Module progress</div>
                <p className="this-page-shows-your neuehaasgroteskdisplaypro-45-light-congress-blue-24px">
                  This page shows your progress through the course. You advance
                  by completing chapters, watching videos and answering quizzes
                  correctly. We invite you to try attempting the quizzes as many
                  times as you need.
                </p>
              </div>
            </>
          )}

          <div className="legatum-container">
            <iframe
              id="edx-iframe"
              sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads"
              frameBorder={0}
              src={edxIframeUrl}
              style={{ width: "100%", height: "138vh", marginTop: 43 }}
              allowFullScreen
            ></iframe>
          </div>
          {footerContent}
        </div>
      </div>
    </>
  );
};

export default CourseProgress;
